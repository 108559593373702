import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"


const Bani = ({ data }) => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              fontWeight: `bold`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            БАНИ ИЗ БРЕВНА
          </h2>
        </div>
      </div>
      <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {data.allContentfulWoodbanya.nodes.map(banya => (
          <div key={banya.id}>
            <div className="w-full flex flex-col mb-6 md:flex-col xl:flex-col">
              <div className="flex p-2 justify-center items-center">
                <Img
                  className="w-full"
                  fluid={banya.image.fluid}
                  alt={banya.title}
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="px-2">
                <h3
                  className="mt-4 text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                  style={{
                    fontFamily: `Montserrat`,
                    color: `#F1F1F2`,
                  }}
                >
                  {banya.title}
                </h3>
                <div
                  className="flex flex-row justify-between"
                  style={{
                    fontFamily: `Montserrat`,
                    color: `#F1F1F2`,
                  }}
                >
                  <p className="m-0 text-sm md:text-sm lg:text-sm xl:text-sm">
                    Размер:<br></br> {banya.size}
                  </p>
                  <p className="m-0 text-sm md:text-sm lg:text-sm xl:text-sm">
                    Цена:<br></br> {banya.price}
                  </p>
                  <p className="m-0 text-sm md:text-sm lg:text-sm xl:text-sm">
                    Площадь:<br></br> {banya.square}
                  </p>
                </div>
                <p
                  className="mt-4 text-sm md:text-sm lg:text-sm xl:text-sm"
                  style={{
                    fontFamily: `Montserrat`,

                    color: `#F1F1F2`,
                  }}
                >
                  {documentToReactComponents(banya.description.json)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Bani

export const PageQuery = graphql`
  query Bani {
    allContentfulWoodbanya {
      nodes {
        id
        size
        price
        square
        title
        image {
          fluid(maxWidth: 600, quality: 35) {
            ...GatsbyContentfulFluid
          }
        }
        description {
          json
        }
      }
    }
  }
`
